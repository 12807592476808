<!-- <script lang="ts">
import { defineComponent } from 'vue'

interface IInstance extends ComponentPublicInstance {
  setPathFrom(from: string): void
}

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const instance = vm as IInstance
      instance.setPathFrom(from.path)
    })
  },
})
</script> -->

<script setup lang="ts">
import { ref } from "vue";
import { useToast } from "vuestic-ui";
import ItemDetailsModal from "../components/modals/ItemDetailsModal.vue";
import CartModal from "../components/modals/CartModal.vue";

import { useStorage } from "@vueuse/core";

import { Icon } from '#components'

const orderStore = useStorage("LastOrder", {});

const nuxtApp = useNuxtApp();

import itemPlaceholder from '~/assets/img/B-gray.png'

definePageMeta({
  keepalive: true
})


//const { menuData } = await useFetch('http://localhost:3978:/api/w/categories')

// const { data: menuData, pending, error, refresh } = await useFetch('/api/w/fullMenu', {initialCache : false})

const branchesData = useBranchStore();
const menuData = useMenuStore();
const sharedData = useSharedStore();
const cartData = useCartStore();

const isLoading = ref(false)

const cartStore = useStorage("CartContent", []);
const branchIdStore = useStorage("Bid", 0);

const router = useRouter();
const route = useRoute();

const sessionCookie = useCookie('session')

// nuxtApp.hook("page:start", () => {
//   if (router.currentRoute.value.name == 'index')
//     isLoading.value = true;
// });
// nuxtApp.hook("page:finish", () => {
//   if (router.currentRoute.value.name == 'index') {
//     console.log('index work')
//     console.log(router.currentRoute.value.name)
//     isLoading.value = false;

//     let hasUnavailableItems = false
//     if (branchesData.BranchChanged) {
//       let itemsCats = cartStore.value.reduce((obj, item) => {
//         obj[item['category']] = item
//         return obj
//       }, {})

//       let cats = menuData.categories.reduce((obj, item) => {
//         obj[item['_id']] = item
//         return obj
//       }, {})


//       console.log('branch changed with existing card')
//       console.log(itemsCats)
      
//       for (let cat of Object.keys(itemsCats)) {
//         if (!cats[cat]) {
//           menuData.flaggedCategories[cat] = "1"
//           hasUnavailableItems = true
//         }
//       }

//       // catLoop: for (let cat of menuData.categories) //this is not a long term solution. 
//       //   if (itemsCats[cat._id]) {
//       //     if (cat.availableInBranches.length) {
//       //       for (let br of cat.availableInBranches) {
//       //         if (br == branchesData.Branches[branchesData.SelectedBranchIdx]._id) {
//       //           continue catLoop
//       //         }
//       //       }
//       //       menuData.flaggedCategories[cat._id] = ""
//       //       hasUnavailableItems = true
//       //     }
//       //   }

//         if (hasUnavailableItems) {
//           for (let item of cartData.Cart) {
//             console.log('checking ' + item.name)
//             if (menuData.flaggedCategories[item.category]) {
//               console.log('flagging' + item.name)
//               item["notAvail"] = true
//             }
//           }
//         }
//       }

//     let checkoutQs = parseInt(route.query["checkout"]);
//     if (checkoutQs && checkoutQs == 1) {
//       router.replace({ query: [] });
//       presentCheckoutModal(true)
//     }
//   }
// });

// let pathFrom: string
// const setPathFrom = (path: string) => {
//   pathFrom = path
//   console.log('vue-route::from::', pathFrom)
//   // let checkoutQs = parseInt(route.query["checkout"]);
//   // if (checkoutQs && checkoutQs == 1) {
//   //   presentCheckoutModal(true)
//   // }
// }

// defineExpose({ setPathFrom })

import { onBeforeUnmount, onMounted, computed } from "vue";
import { useBreakpoint } from "vuestic-ui";
import CheckoutModal from "~/components/modals/CheckoutModal.vue";

import LocationModal from "~/components/modals/LocateBranchModal.vue";

const breakpoints = useBreakpoint();

const isMobile = ref(false);
const isTablet = ref(false);

const isMdUp = ref(false);

const onResize = () => {
  isMobile.value = breakpoints.xs;
  isTablet.value  = breakpoints.mdDown;
  isMdUp.value = breakpoints.mdUp;
};

// onBeforeMount(() => {
  // window.addEventListener('scroll', handleScroll)
// })

onMounted(async () => {
  window.addEventListener("resize", onResize);
  onResize();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
  // window.removeEventListener('scroll', handleScroll)
});




const { init, notify, close, closeAll } = useToast();

refreshCookie('bidSelection')

let bidCookie = useCookie('bidSelection', {
  sameSite: true,
})

const branchIdx = ref(0);
console.log(route.query);
// let qsId = parseInt(route.query["bid"]);
// if (qsId >= 0 && qsId < branchesData.Branches.length) {
//   console.log('qsId: ' + qsId)
//   branchIdx.value = route.query["bid"].valueOf();
//   branchesData.updateSelectedBranch(branchIdx.value);
//   // branchIdStore.value = qsId;
// } else if (bidCookie.value && bidCookie.value > -1) {
//   console.log('updated cookie')
//   branchIdx.value = bidCookie.value;
//   branchesData.updateSelectedBranch(branchIdx.value);
// } else if (branchIdStore.value > -1) {
//   console.log('selected Branch: ' + branchIdStore.value)
//   branchIdx.value = branchIdStore.value;
//   branchesData.updateSelectedBranch(branchIdx.value);
// } else {
//   console.log('selected branchIdx: ' + branchesData.SelectedBranchIdx)
//   branchIdx.value = branchesData.SelectedBranchIdx;
// }

// console.log(`(${qsId}) && (${branchIdStore.value} && ${parseInt(branchIdStore.value) > -1}) && ${qsId} != ${parseInt(branchIdStore.value)} && ${cartStore.value.length}`)
// if ((qsId >= 0 && qsId < branchesData.Branches.length) && (branchIdStore.value && parseInt(branchIdStore.value) > -1) && (qsId != parseInt(branchIdStore.value)) && cartStore.value.length) {
//   console.log('branch changed with existing card')
//   branchesData.BranchChanged = true
// }
// branchIdStore.value = qsId;
// bidCookie.value = branchIdx.value

// let checkoutQs = parseInt(route.query["checkout"]);
// if (checkoutQs && checkoutQs == 1) {
//   //presentCheckoutModal(true)
// }

//sharedData.ShowBanner()
//sharedData.ShowCartButton(true);

// else if (branchesData.SelectedBranchId) {
//   branchIdx.value = branchesData.SelectedBranchId
// }

// console.log("HERE 2");
// await callOnce("menuForBranch", async () => {
//   console.log("called once");
//   let bid = branchesData.Branches[branchIdx.value]["branchId"];
//   console.log(`IDX ${branchIdx.value} - BID ${bid}`);
//   branchesData.updateSelectedBranchId(bid)
//   await menuData.fetchForBranchId(bid);
// });

//   await useAsyncData('data',
// 	async (nuxtApp) => {
// 		delete nuxtApp?.payload?.path;
// 	}
// );

router.replace({ query: [] });

//await menuData.fetchForBranchId(branchesData.SelectedBranchId)

let promos = menuData.promos.map((item) => item.image.url);

//const selectedBranchName = ref(branchesData.Branches[branchIdx.value].name);
const loading = ref<Boolean>(menuData.pending);
const showBranchModal = ref<Boolean>(false);
const showPromos = ref(menuData.promos);
const shownPromo = ref<Number>(0);
const showItemModal = ref<Boolean>(false);
const selectedItem = ref<Object>({});
const showCartModal = ref<Boolean>(false);
const showCheckoutModal = ref<Boolean>(false);
const showConfirmationModal = ref<Boolean>(false);
const showLocationModal = ref<Boolean>(false);
const showLocationPopover = ref<Boolean>(true);

const infoModalMessage = ref('You can choose the order type during checkout!')
const showInfoModal = ref(false)

const stagedItems = ref<Object[]>([]); //

//const sectionsControl = ref(Array(menuData.categories.length ?? 0).fill(false));
//sectionsControl.value[0] = true;

const selectedTab = ref(-1);

if (cartStore.value.length != cartData.Cart.length) {
  cartData.Cart = cartStore.value;
  cartData.updateTotalAndCount();
  sharedData.ShowCartButton(true);
}

// When accessing /posts/1, route.params.id will be 1
//console.log(route.params.id + "  " + route.name + " " + route.fullPath);

onMounted(async () => {
  // if (route.name == "menu-item" && route.params.id) {
  //   let item = (selectedItem.value = menuData.menu["5edcc20791351b1750c65fe1"].items[0]);
  //   presentItemModal(null, item, true);
  // }
});

const sectionExpanded = function (index) {
  if (!index) return true;
  return false;
};

const presentItemModal = (event, item, show) => {
  if (item) selectedItem.value = item;

  showItemModal.value = show;
  if (!show) {
    if (route.params.id && route.name == "menu-item") router.replace({ path: "/menu" });
  }
};

const goToLogin = async () => {
    showCheckoutModal.value = false;
    await navigateTo({ path: '/login', query: { from: 'checkout'} })
}

const presentCartModal = (event, show) => {
  showCartModal.value = show;
};

const refreshPage = async () => {
  await navigateTo({ path: '/' }, {external: true})
}

// const presentOrderDetailsModal = () => {
//   showOrderTrackModal.value = true
//   // check if there's order id and set it
// }

// const presentLocationModal = async () => {
//   refreshCookie('session')
//   console.log(sessionCookie.value)
//   if (sessionCookie.value) {
//     showLocationModal.value = true
//   } else {
//     await navigateTo({ path: '/login', query: { from: 'checkout'} })
//   }
// };

const goToCheckout = async () => {
  refreshCookie('session')
  console.log(sessionCookie.value)
  if (useCookie('session').value) {
    presentCheckoutModal(null)
  } else {
    presentCartModal(null, false);
    await navigateTo({ path: '/login', query: { from: 'checkout'} })
  }
};

const goToCheckoutFromModal = () => {
  showConfirmationModal.value = false
  showCheckoutModal.value = true
}

const presentCheckoutModal = (even) => {
  presentCartModal(null, false);
    if (orderStore.value && orderStore.value["ts"]) {
      let odate = orderStore.value["ts"]
      let ndate = Date.now()
      if (((ndate - odate) / 1000) < 18000) {
        showConfirmationModal.value = true
        return
      }
    }      

  showCheckoutModal.value = true;
};

const backToCart = () => {
  showCheckoutModal.value = false;
  presentCartModal(null, true);
};

const orderPlaced = () => {
  showCheckoutModal.value = false;
}

const changeBranch = async (event, idx, id) => {
  console.log("khaaraa " + idx + 'sss' + id);
  selectedBranchName.value = branchesData.Branches[idx].name;
  branchesData.updateSelectedBranchId(id);
  branchesData.updateSelectedBranch(idx);
  loading.value = true;
  try {
    //await refreshNuxtData()
    //this.$window.reload()
    //reloadNuxtApp({persistState: true})
    await navigateTo({ path: "/", query: { bid: idx } }, { external: true });
  } finally {
    loading.value = false;
  }
  // loading.value = true
  // menuData.fetchForBranchId(branchesData.Branches[idx].branchId)
  // .then(() => {
  //   loading.value = false
  // })
};

const categoryName = function (idx) {
  let cat = menuData.categories[idx];
  let itemsCount =
    menuData.menu[cat._id] && menuData.menu[cat._id].items
      ? menuData.menu[cat._id].items.length
      : 0;
  return `${cat.name} (${itemsCount})`;
};

function addToFav (item) {
  //notify(`${item.refItem.name} was added to cart!`);
  this.$root.presentAuthModal()
  this.$parent.presentAuthModal()
};

function addToCartFromModal(item) {
  console.log(item);
  cartData.addItemToCart(item);
  cartData.updateTotalAndCount();
  if (cartData.ItemsInCart > 0 && !sharedData.CartButtonShown) {
    sharedData.ShowCartButton(true);
  }
  // cartStore.value.push(item)
  // stagedItems.value.push(item)
  notify(`${item.refItem["name"] ?? "item"} was added to cart!`);
  presentItemModal(null, null, false);
}

const id = useId()

function addToCart(item) {
  console.log(item)
  var newItem = {}
    // item.name = props.selectedItem.name

    let ogSubtotal = item.price.small ?? item.price.regular ?? Object.values(item.price)[0]
    let size = item.price.small ? 'small' : item.price.regular ? 'regular' : Object.keys(item.price)[0]
    let cat = item.category
    console.log('cat: ' + cat)
    newItem.id = id
    newItem.name = item.name
    newItem.size = size
    newItem.paperCup = true
    newItem.quantity = 1
    newItem.subtotal = ogSubtotal
    newItem.unitSubtotal = ogSubtotal
    newItem.optionsPrices = 0
    newItem.ogSubtotal = ogSubtotal
    newItem['category'] = cat
    newItem.options = []
    newItem.refItem = item

    addToCartFromModal(newItem)

}

const tabSelectionChanged = () => {
  sectionsControl.value[selectedTab.value] = true;
  //await navigateTo({ path: '/', hash: `#${menuData.categories[selectedTab.value].key}`});
  setTimeout(() => {
    document.getElementById(`${menuData.categories[selectedTab.value].key}`)
      .scrollIntoView({ behavior: "smooth", block: "start" });
      selectedTab.value = null
  }, 100);
};

const trimText = (text) => {
  let trimmed = text
  if (text.length > 46) {
    trimmed = text.substring(0, 40)
    trimmed += "..."
  }
  return trimmed 
}

</script>

<template>
  <NuxtLayout>
    <div class="flex flex-col scroll-smooth">
      <!-- <NuxtLoadingIndicator /> -->     
        <VaProgressBar v-if="isLoading" indeterminate />
        <!-- <VaSpacer class="spacer" /> -->
        <div class="flex flex-row mt-2 flex-wrap">
          <h6 class="text-lg font-bold text-center w-full h-full">Get your foodin.bio soon!</h6>
        </div>
    </div>
  </NuxtLayout>
</template>

<style scoped lang="scss">
:deep(.list__item + .list__item) {
  margin-top: 20px;
}

:deep(.va-scroll-container__content) {
  height: -webkit-fit-content
}
</style>
